/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, Route, Switch } from 'react-router-dom';
import { showToastMessage } from '~/services/notification';

import Catalogo from '~/pages/Catalogo';
import CatalogoPerfil from '~/pages/Catalogo/Perfil';
import ProfissionalServico from '~/pages/Catalogo/ProfissionalServico';
import EscolhaAcesso from '~/pages/Acesso';
import Tutorial from '~/pages/Usuario/Tutorial';

import CriarSenha from '~/pages/CriarSenha';

// Usuario fisico ou juridico
// Usuario
import CadastroEscolha from '~/pages/Usuario/Escolha';
import FormPessoaFisica from '~/pages/Usuario/PessoaFisica';
import CatalogoPessoaJuridica from '~/pages/Catalogo/PessoaJuridica';
import SolicitanteFilm from '~/pages/Usuario/SolicitanteFilmagem';
import SolicitacaoNovaObra from '~/pages/Usuario/SolicitacaoNovaObra';
import AprovacaoObras from '~/pages/Usuario/AprovacaoObras';
import FinalizacaoCadastro from '~/pages/Usuario/FinalizacaoCadastro';
import Termo from '~/pages/Usuario/Termo';
import HomeUser from '~/pages/Usuario/Home';
import ObraDetalhe from '~/pages/Usuario/Obra';
import SolicitacaoLocacao from '~/pages/Usuario/SolicitacaoLocacao';
import PesquisaSocioeconomica from '~/pages/Usuario/Pesquisas/PesquisaSocioeconomica';
import Perfil from '~/pages/Usuario/Perfil';
import RedirecionaEndpoint from '~/pages/Usuario/RedirecionaEndpoint';

// Admin
import Auth from '~/pages/Auth';
import RedirectPage from '~/pages/Redirect';
import RecoveryPassword from '~/pages/Auth/recovery';
import Home from '~/pages/Admin/Home';
import PrintTable from '~/pages/Admin/Home/PrintTable';
import DetalheObra from '~/pages/Admin/DetalheObra';
import Obra from '~/pages/Admin/Obra';
import Locacao from '~/pages/Admin/Locacao';
import VisitasTecnicas from '~/pages/Admin/VisitasTecnicas';
import CartasDeApoio from '~/pages/Admin/CartasDeApoio';
import Avisos from '~/pages/Admin/Avisos';
import Equipe from '~/pages/Admin/Equipe';
import Usuario from '~/pages/Admin/Usuario';
import Relatorio from '~/pages/Admin/Relatorio';
import RelatorioLogin from '~/pages/Admin/RelatorioLogin';
import PrintTableLocacao from '~/pages/Admin/Locacao/PrintTableLocacao';
import PrintTableRelatorioLogin from '~/pages/Admin/RelatorioLogin/PrintTableRelatorioLogin';
import GerenciamentoEquipe from '~/pages/Admin/GerenciamentoEquipe';
import CienciaDeFilmagem from '~/pages/Admin/CienciaDeFilmagem';
import VisitaTecnica from '~/pages/Usuario/VisitaTecnica';
import NovaCartaApoio from '~/pages/Usuario/CartaApoio';
import CienciaFilmagemInterna from '~/pages/Usuario/CienciaFilmagemInterna';
import DocumentosChat from '~/pages/Admin/DocumentosChat';
import AlteracoesSolicitadas from '~/pages/Admin/AlteracoesSolicitadas';
import EditObra from '~/pages/Usuario/Obra/Edit';
import { ThemeContext } from '~/styles/ThemeProvider';
import Tema from '~/pages/Admin/Tema';
import ManutencaoVideosTutoriais from '~/pages/Admin/ManutencaoVideosTutoriais';
import { getSubdomainName } from '~/utils/MultiTenancyUtil';
import { NotFound } from '~/pages/Errors/NotFound';
import {
  IlhabelaCameraCar,
  IlhabelaCameraCarEdit,
  IlhabelaCemiterio,
  IlhabelaCemiterioEdit,
  IlhabelaComunidadesTradicionais,
  IlhabelaComunidadesTradicionaisEdit,
  IlhabelaEdificiosSede,
  IlhabelaEdificiosSedeEdit,
  IlhabelaEquipamentosEducacao,
  IlhabelaEquipamentosEducacaoEdit,
  IlhabelaEquipamentosSaude,
  IlhabelaEquipamentosSaudeEdit,
  IlhabelaEspacosDeCultura,
  IlhabelaEspacosDeCulturaEdit,
  IlhabelaEspacosSmad,
  IlhabelaEspacosSmadEdit,
  IlhabelaFeiraLivre,
  IlhabelaFeiraLivreEdit,
  IlhabelaFilmagemEsportiva,
  IlhabelaFilmagemEsportivaEdit,
  IlhabelaGaleria,
  IlhabelaGaleriaEdit,
  IlhabelaOutrosEspacos,
  IlhabelaOutrosEspacosEdit,
  IlhabelaPaisagensNaturais,
  IlhabelaPaisagensNaturaisEdit,
  IlhabelaParques,
  IlhabelaParquesEdit,
  IlhabelaPraca,
  IlhabelaPracaEdit,
  IlhabelaPraias,
  IlhabelaPraiasEdit,
  IlhabelaProjecoes,
  IlhabelaProjecoesEdit,
  IlhabelaReservasVagasFilmInternas,
  IlhabelaReservasVagasFilmInternasEdit,
  IlhabelaReservasVagasVinculado,
  IlhabelaReservasVagasVinculadoEdit,
  IlhabelaUtilizacaoVia,
  IlhabelaUtilizacaoViaEdit,
  SpcineAcessoCalcadoes,
  SpcineAcessoCalcadoesEdit,
  SpcineAutodromo,
  SpcineAutodromoEdit,
  SpcineCameraCar,
  SpcineCameraCarEdit,
  SpcineCemiterio,
  SpcineCemiterioEdit,
  SpcineEdificiosSede,
  SpcineEdificiosSedeEdit,
  SpcineEquipamentosEducacao,
  SpcineEquipamentosEducacaoEdit,
  SpcineEquipamentosSaude,
  SpcineEquipamentosSaudeEdit,
  SpcineEspacosDeCultura,
  SpcineEspacosDeCulturaEdit,
  SpcineEspacosSmad,
  SpcineEspacosSmadEdit,
  SpcineFeiraLivre,
  SpcineFeiraLivreEdit,
  SpcineFilmagemEsportiva,
  SpcineFilmagemEsportivaEdit,
  SpcineGaleria,
  SpcineGaleriaEdit,
  SpcineMercado,
  SpcineMercadoEdit,
  SpcineOutrosEspacos,
  SpcineOutrosEspacosEdit,
  SpcineParques,
  SpcineParquesEdit,
  SpcinePlanetario,
  SpcinePlanetarioEdit,
  SpcinePraca,
  SpcinePracaEdit,
  SpcineProjecoes,
  SpcineProjecoesEdit,
  SpcineReservasVagasFilmInternas,
  SpcineReservasVagasFilmInternasEdit,
  SpcineReservasVagasVinculado,
  SpcineReservasVagasVinculadoEdit,
  SpcineTeatro,
  SpcineTeatroEdit,
  SpcineTerminaisOnibus,
  SpcineTerminaisOnibusEdit,
  SpcineUtilizacaoVia,
  SpcineUtilizacaoViaEdit,
} from '~/pages/Usuario/Locacoes';
import DuvidasFrequentes from '~/pages/Usuario/DuvidasFrequentes';

const routes = {
  public: {
    ilhacine: [
      { path: '/login', component: Auth },
      { path: '/forgot-password', component: RecoveryPassword },
      { path: '/criar/senha', component: CriarSenha },
      { path: '/catalogo/perfil', component: CatalogoPerfil },
      {
        path: '/catalogo/profissional-servico',
        component: ProfissionalServico,
      },
      { path: '/duvidas-frequentes', component: DuvidasFrequentes },
      { path: '/redirect/:hashed', component: RedirectPage },
    ],
    spcine: [
      { path: '/login', component: Auth },
      { path: '/forgot-password', component: RecoveryPassword },
      { path: '/criar/senha', component: CriarSenha },
      { path: '/catalogo/perfil', component: CatalogoPerfil },
      {
        path: '/catalogo/profissional-servico',
        component: ProfissionalServico,
      },
      { path: '/video-tutorial', component: Tutorial },
      { path: '/redirect/:hashed', component: RedirectPage },
    ],
  },

  private: [
    { path: '/escolha-acesso', component: EscolhaAcesso },
    { path: '/cadastro-escolha', component: CadastroEscolha },
    { path: '/cadastro-pessoa-fisica', component: FormPessoaFisica },
    { path: '/catalogo/cadastro-pj', component: CatalogoPessoaJuridica },
    { path: '/catalogo/cadastro', component: Catalogo },
  ],

  hasPermission: [
    { path: '/', component: Home, hasRoles: ['Administrador', 'Moderador'] },
    {
      path: '/register/solicitante/filmagem',
      component: SolicitanteFilm,
      hasRoles: ['Pessoa Fisica', 'Pessoa Jurídico', 'Administrador'],
    },
    {
      path: '/imprimir',
      component: PrintTable,
      hasRoles: ['Administrador', 'Moderador'],
    },
    {
      path: '/obra',
      component: DetalheObra,
      hasRoles: ['Administrador', 'Moderador'],
    },
    {
      path: '/obras',
      component: Obra,
      hasRoles: ['Administrador', 'Moderador'],
    },
    {
      path: '/solicitacao-nova-obra',
      component: SolicitacaoNovaObra,
      hasRoles: ['Pessoa Fisica', 'Pessoa Jurídico', 'Administrador'],
    },
    {
      path: '/aprovacao-obras',
      component: AprovacaoObras,
      hasRoles: ['Pessoa Fisica', 'Pessoa Jurídico', 'Administrador'],
    },
    {
      path: '/finalizacao-cadastro',
      component: FinalizacaoCadastro,
      hasRoles: ['Pessoa Fisica', 'Pessoa Jurídico', 'Administrador'],
    },
    {
      path: '/termo',
      component: Termo,
      hasRoles: ['Pessoa Fisica', 'Pessoa Jurídico', 'Administrador'],
    },
    {
      path: '/home-user',
      component: HomeUser,
      hasRoles: ['Pessoa Fisica', 'Pessoa Jurídico', 'Administrador'],
    },
    {
      path: '/obra-detalhe',
      component: ObraDetalhe,
      hasRoles: ['Pessoa Fisica', 'Pessoa Jurídico', 'Administrador'],
    },
    {
      path: '/imprimir-locacoes',
      component: PrintTableLocacao,
      hasRoles: ['Administrador', 'Moderador'],
    },
    {
      path: '/locacoes',
      component: Locacao,
      hasRoles: ['Administrador', 'Moderador'],
    },
    {
      path: '/avisos',
      component: Avisos,
      hasRoles: ['Administrador', 'Moderador'],
    },
    {
      path: '/equipes',
      component: Equipe,
      hasRoles: ['Administrador', 'Moderador'],
    },
    {
      path: '/usuarios',
      component: Usuario,
      hasRoles: ['Administrador', 'Moderador'],
    },
    {
      path: '/relatorios',
      component: Relatorio,
      hasRoles: ['Administrador', 'Moderador'],
    },
    {
      path: '/relatorio-login',
      component: RelatorioLogin,
      hasRoles: ['Administrador', 'Moderador'],
    },
    {
      path: '/tema',
      component: Tema,
      hasRoles: ['Administrador', 'Moderador'],
    },
    {
      path: '/manutencao-videos-tutoriais',
      component: ManutencaoVideosTutoriais,
      hasRoles: ['Administrador', 'Moderador'],
    },
    {
      path: '/imprimir-relatorio-login',
      component: PrintTableRelatorioLogin,
      hasRoles: ['Administrador', 'Moderador'],
    },
    {
      path: '/gerenciamento-equipe',
      component: GerenciamentoEquipe,
      hasRoles: ['Administrador', 'Moderador'],
    },
    {
      path: '/solicitacao-nova-locacao',
      component: SolicitacaoLocacao,
      hasRoles: ['Pessoa Fisica', 'Pessoa Jurídico', 'Administrador'],
    },
    {
      path: '/solicitacao-nova-locacao/:locacao/visita-tecnica',
      component: VisitaTecnica,
      hasRoles: ['Pessoa Fisica', 'Pessoa Jurídico', 'Administrador'],
    },
    {
      path: '/pesquisas/pesquisa-socioeconomica',
      component: PesquisaSocioeconomica,
      hasRoles: ['Pessoa Fisica', 'Pessoa Jurídico', 'Administrador'],
    },
    {
      path: '/perfil',
      component: Perfil,
      hasRoles: ['Pessoa Fisica', 'Pessoa Jurídico', 'Administrador'],
    },
    {
      path: '/redireciona-endpoint',
      component: RedirecionaEndpoint,
      hasRoles: ['Pessoa Fisica', 'Pessoa Jurídico', 'Administrador'],
    },
    {
      path: '/visitas-tecnicas',
      component: VisitasTecnicas,
      hasRoles: ['Administrador', 'Moderador'],
    },
    {
      path: '/documentos-chat',
      component: DocumentosChat,
      hasRoles: ['Administrador', 'Moderador'],
    },
    {
      path: '/ciencia-filmagem',
      component: CienciaDeFilmagem,
      hasRoles: ['Administrador', 'Moderador'],
    },
    {
      path: '/alteracoes-solicitadas',
      component: AlteracoesSolicitadas,
      hasRoles: ['Administrador', 'Moderador'],
    },
    {
      path: '/cartas-de-apoio',
      component: CartasDeApoio,
      hasRoles: ['Administrador', 'Moderador'],
    },
    {
      path: '/solicitacao-nova-carta-de-apoio',
      component: NovaCartaApoio,
      hasRoles: ['Pessoa Fisica', 'Pessoa Jurídico', 'Administrador'],
    },
    {
      path: '/ciencia-filmagem-interna',
      component: CienciaFilmagemInterna,
      hasRoles: ['Pessoa Fisica', 'Pessoa Jurídico', 'Administrador'],
    },
    {
      path: '/obra/edit',
      component: EditObra,
      hasRoles: ['Pessoa Fisica', 'Pessoa Jurídico', 'Administrador'],
    },
  ],

  locacao: {
    ilhacine: [
      // news
      {
        path: '/solicitacao-nova-locacao/locacao-com-tradicional',
        component: IlhabelaComunidadesTradicionais,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-paisagem-natural',
        component: IlhabelaPaisagensNaturais,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-praia',
        component: IlhabelaPraias,
      },
      // news edit
      {
        path: '/solicitacao-nova-locacao/locacao-com-tradicional/edit',
        component: IlhabelaComunidadesTradicionaisEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-paisagem-natural/edit',
        component: IlhabelaPaisagensNaturaisEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-praia/edit',
        component: IlhabelaPraiasEdit,
      },
      // old
      {
        path: '/solicitacao-nova-locacao/locacao-cemiterio',
        component: IlhabelaCemiterio,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-feira-livre',
        component: IlhabelaFeiraLivre,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-filmagem-parque',
        component: IlhabelaParques,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-filmagem-esportivo',
        component: IlhabelaFilmagemEsportiva,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-edificio',
        component: IlhabelaEdificiosSede,
      },
      {
        path: '/solicitacao-nova-locacao/reservas-vagas-vinculado',
        component: IlhabelaReservasVagasVinculado,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-reserva-vaga',
        component: IlhabelaReservasVagasFilmInternas,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-praca-publica',
        component: IlhabelaPraca,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-equipamentos-saude',
        component: IlhabelaEquipamentosSaude,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-galeria',
        component: IlhabelaGaleria,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-espacos-cultura',
        component: IlhabelaEspacosDeCultura,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-outros-espacos',
        component: IlhabelaOutrosEspacos,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-equipamento-educacao',
        component: IlhabelaEquipamentosEducacao,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-espacos-smad',
        component: IlhabelaEspacosSmad,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-projecao',
        component: IlhabelaProjecoes,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-utilizacao-via',
        component: IlhabelaUtilizacaoVia,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-camera-car',
        component: IlhabelaCameraCar,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-praca-publica/edit',
        component: IlhabelaPracaEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-utilizacao-via/edit',
        component: IlhabelaUtilizacaoViaEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-filmagem-parque/edit',
        component: IlhabelaParquesEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-camera-car/edit',
        component: IlhabelaCameraCarEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-outros-espacos/edit',
        component: IlhabelaOutrosEspacosEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-filmagem-esportivo/edit',
        component: IlhabelaFilmagemEsportivaEdit,
      },
      {
        path: '/solicitacao-nova-locacao/reservas-vagas-vinculado/edit',
        component: IlhabelaReservasVagasVinculadoEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-reserva-vaga/edit',
        component: IlhabelaReservasVagasFilmInternasEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-feira-livre/edit',
        component: IlhabelaFeiraLivreEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-galeria/edit',
        component: IlhabelaGaleriaEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-espacos-cultura/edit',
        component: IlhabelaEspacosDeCulturaEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-equipamento-educacao/edit',
        component: IlhabelaEquipamentosEducacaoEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-espacos-smad/edit',
        component: IlhabelaEspacosSmadEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-edificio/edit',
        component: IlhabelaEdificiosSedeEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-equipamentos-saude/edit',
        component: IlhabelaEquipamentosSaudeEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-cemiterio/edit',
        component: IlhabelaCemiterioEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-projecao/edit',
        component: IlhabelaProjecoesEdit,
      },
    ],
    spcine: [
      {
        path: '/solicitacao-nova-locacao/locacao-cemiterio',
        component: SpcineCemiterio,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-feira-livre',
        component: SpcineFeiraLivre,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-filmagem-mercado',
        component: SpcineMercado,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-filmagem-parque',
        component: SpcineParques,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-terminal',
        component: SpcineTerminaisOnibus,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-teatro',
        component: SpcineTeatro,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-filmagem-esportivo',
        component: SpcineFilmagemEsportiva,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-edificio',
        component: SpcineEdificiosSede,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-autodromo',
        component: SpcineAutodromo,
      },
      {
        path: '/solicitacao-nova-locacao/reservas-vagas-vinculado',
        component: SpcineReservasVagasVinculado,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-reserva-vaga',
        component: SpcineReservasVagasFilmInternas,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-acesso-calcadoes',
        component: SpcineAcessoCalcadoes,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-praca-publica',
        component: SpcinePraca,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-equipamentos-saude',
        component: SpcineEquipamentosSaude,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-galeria',
        component: SpcineGaleria,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-espacos-cultura',
        component: SpcineEspacosDeCultura,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-outros-espacos',
        component: SpcineOutrosEspacos,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-equipamento-educacao',
        component: SpcineEquipamentosEducacao,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-planetario',
        component: SpcinePlanetario,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-espacos-smad',
        component: SpcineEspacosSmad,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-projecao',
        component: SpcineProjecoes,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-utilizacao-via',
        component: SpcineUtilizacaoVia,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-camera-car',
        component: SpcineCameraCar,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-praca-publica/edit',
        component: SpcinePracaEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-utilizacao-via/edit',
        component: SpcineUtilizacaoViaEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-filmagem-parque/edit',
        component: SpcineParquesEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-camera-car/edit',
        component: SpcineCameraCarEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-terminal/edit',
        component: SpcineTerminaisOnibusEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-outros-espacos/edit',
        component: SpcineOutrosEspacosEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-filmagem-esportivo/edit',
        component: SpcineFilmagemEsportivaEdit,
      },
      {
        path: '/solicitacao-nova-locacao/reservas-vagas-vinculado/edit',
        component: SpcineReservasVagasVinculadoEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-reserva-vaga/edit',
        component: SpcineReservasVagasFilmInternasEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-acesso-calcadoes/edit',
        component: SpcineAcessoCalcadoesEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-filmagem-mercado/edit',
        component: SpcineMercadoEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-autodromo/edit',
        component: SpcineAutodromoEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-feira-livre/edit',
        component: SpcineFeiraLivreEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-teatro/edit',
        component: SpcineTeatroEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-galeria/edit',
        component: SpcineGaleriaEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-espacos-cultura/edit',
        component: SpcineEspacosDeCulturaEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-equipamento-educacao/edit',
        component: SpcineEquipamentosEducacaoEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-planetario/edit',
        component: SpcinePlanetarioEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-espacos-smad/edit',
        component: SpcineEspacosSmadEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-edificio/edit',
        component: SpcineEdificiosSedeEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-equipamentos-saude/edit',
        component: SpcineEquipamentosSaudeEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-cemiterio/edit',
        component: SpcineCemiterioEdit,
      },
      {
        path: '/solicitacao-nova-locacao/locacao-projecao/edit',
        component: SpcineProjecoesEdit,
      },
    ],
  },
};

export default function Routes() {
  const data = JSON.parse(localStorage.getItem('persist:Spcine'));
  const { theme } = useContext(ThemeContext);

  return (
    <>
      <Helmet>
        <title>{theme.system_name || 'SP Cine'}</title>
      </Helmet>
      <Switch>
        {(routes.public[getSubdomainName()] || routes.public['spcine'])?.map(
          (route, i) => (
            <Route
              key={i}
              path={route.path}
              component={route.component}
              exact
            />
          ),
        )}

        {routes.private.map((route, i) => (
          <PrivateRoute
            key={i}
            isAuthenticated={data && data.auth.logado}
            path={route.path}
            component={route.component}
            exact
          />
        ))}

        {routes.hasPermission.map((route, i) => (
          <HasPermission
            key={i}
            path={route.path}
            isAuthenticated={data && data.auth.logado}
            exact
            hasRoles={route.hasRoles}
            component={route.component}
          />
        ))}

        {(routes.locacao[getSubdomainName()] || routes.locacao['spcine'])?.map(
          (route, i) => (
            <HasPermission
              key={i}
              path={route.path}
              exact
              isAuthenticated={data && data.auth.logado}
              hasRoles={['Pessoa Fisica', 'Pessoa Jurídico', 'Administrador']}
              component={route.component}
            />
          ),
        )}

        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </>
  );
}

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated === true ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/login' }} />
      )
    }
  />
);

function GuestRoute({ component: Component, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated === false ? (
          <Component {...rest} />
        ) : (
          <Redirect
            to={{ pathname: isAuthenticated ? '/escolha-acesso' : '/login' }}
          />
        )
      }
    />
  );
}

function HasPermission({
  component: Component,
  isAuthenticated,
  hasRoles,
  isAllowed = false,
  ...rest
}) {
  const localFisica = localStorage.getItem('@roleP');
  const fisica = JSON.parse(String(localFisica));
  const localJuridica = localStorage.getItem('@roleJ');
  const juridica = JSON.parse(String(localJuridica));
  const localAdmin = localStorage.getItem('@roleAd');
  const admin = JSON.parse(String(localAdmin));
  const localModerador = localStorage.getItem('@roleMd');
  const moderador = JSON.parse(String(localModerador));

  isAllowed =
    hasRoles.includes(fisica) ||
    hasRoles.includes(juridica) ||
    hasRoles.includes(admin) ||
    hasRoles.includes(moderador);

  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated === true && isAllowed === true ? (
          <Component {...props} />
        ) : (
          (showToastMessage({
            type: 'error',
            text: 'Oops! Você não tem permissão para acessar está pagina',
          }),
          (<Redirect to={{ pathname: '/escolha-acesso' }} />))
        )
      }
    />
  );
}
