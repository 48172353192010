import React from 'react';
import { getSubdomainName } from '~/utils/MultiTenancyUtil';
import spFilmComissionLogo from '~/assets/images/logo-spfilm-comission-negativo.png';
import spcineLogoNegativo from '~/assets/images/logo-spcine-negativo.png';
import culturaSpLogoNegativo from '~/assets/images/logo-cultura-saopaulo-negativo.png';
import spcineLogo from '~/assets/images/logo-spcine.png';
import ilhaBelaLogo from '~/assets/images/logo-ilhabela.png';
import prefeituraIlhaBelaLogo from '~/assets/images/prefeitura-ilhabela.png';
import ilhaBelaLogoNegativo from '~/assets/images/logo-ilhabela-negativo.png';
import culturaSpLogo from '~/assets/images/logo-cultura-saopaulo.png';
import heroLoginBg from '~/assets/images/usuario/hero-login-bg.jpg';
import heroLoginBgIlhaBela from '~/assets/images/usuario/hero-login-bg2.jpg';
import companyProfileLogo from '~/assets/images/usuario/company-profile.png';
import companyProfileLogoIlhaBela from '~/assets/images/usuario/company-profile-ilhabela.jpeg';
import precosFilmagensEquipamentosPublicos from '~/assets/documets/Precos_das_Filmagens_em_Equipamentos_Publicos.pdf';
import ilhacinePrecosFilmagensEquipamentosPublicos from '~/assets/documets/Ilhacine_Precos_das_Filmagens_em_Equipamentos_Publicos.pdf';
import CatalogoLocacaoIlhaBela from '~/assets/documets/CatalogoLocacaoIlhaBela.pdf';
import precosFilmagensVia from '~/assets/documets/Precos_das_Filmagens_em_Vias.pdf';
// import ilhacinePrecosFilmagensVia from '~/assets/documets/Ilhacine_Precos_das_Filmagens_em_Equipamentos_Publicos.pdf';
import associationFilmLogoFooter from '~/assets/images/acesso/association-film-logo-branco.png';
import lafcnLogoFooter from '~/assets/images/acesso/lafcn-logo-branco.png';
import infoBalls from '~/assets/images/info-balls.png';

// endereco icons
import PhoneCallIcon from '~/assets/images/acesso/rodape-icons/phone-call.png';
import PointerMapIcon from '~/assets/images/acesso/rodape-icons/point-map.png';
import MailLogoIcon from '~/assets/images/acesso/rodape-icons/mail.png';

// social media
import FacebookLogoIcon from '~/assets/images/acesso/rodape-icons/facebook.png';
import InstagramLogoIcon from '~/assets/images/acesso/rodape-icons/instagram-logo.png';
import TwitterLogoIcon from '~/assets/images/acesso/rodape-icons/twitter.png';
import YoutubeLogoIcon from '~/assets/images/acesso/rodape-icons/youtube.png';
import TelegramLogoIcon from '~/assets/images/acesso/rodape-icons/telegram.png';
import LinkedinLogoIcon from '~/assets/images/acesso/rodape-icons/linkedin.png';
import bgHeader from '~/assets/images/usuario/bg-header.png';

const clientResources = {
  spcine: {
    tenant: 'spcine',
    displayName: 'São Paulo Film Commission',
    images: {
      system: {
        spFilmComissionLogo,
        spcineLogo,
        bgHeader,
        culturaSpLogo,
        altLogo: 'Spcine Logo',
        altCultura: 'Secretaria de Cultura Logo',
        companyProfileLogo,
        spcineLogoNegativo: <img src={spcineLogoNegativo} alt="Spcine Logo" />,
        culturaSpLogoNegativo: (
          <img src={culturaSpLogoNegativo} alt="Secretaria de Cultura Logo" />
        ),
      },
      login: {
        heroLoginBg,
        infoBalls,
        lafcnLogoFooter: (
          <img src={lafcnLogoFooter} alt="LA FCN Logo Footer" width={115} />
        ),
        associationFilmLogoFooter: (
          <img
            src={associationFilmLogoFooter}
            alt="Association of Film Comissioners International Logo Footer"
            width={215}
          />
        ),
      },
    },
    login: {
      duvidasFrequentes: 'video-tutorial',
      urlDoc: null,
    },
    social: {
      login: {
        facebook: (
          <a
            href="https://pt-br.facebook.com/spcinesp/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={FacebookLogoIcon}
              alt="Facebook Logo"
              width={30}
              height={30}
            />
          </a>
        ),
        twitter: (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a href="#" target="_blank" rel="noreferrer">
            <img
              src={TwitterLogoIcon}
              alt="Twitter Logo"
              width={30}
              height={30}
            />
          </a>
        ),
        instagram: (
          <a
            href="https://www.instagram.com/spfilmcommission/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={InstagramLogoIcon}
              alt="Instagram Logo"
              width={30}
              height={30}
            />
          </a>
        ),
        telegram: (
          <a href="https://t.me/canalspfilm" target="_blank" rel="noreferrer">
            <img
              src={TelegramLogoIcon}
              alt="Telegram Logo"
              width={30}
              height={30}
            />
          </a>
        ),
        youtube: (
          <a
            href="https://www.youtube.com/c/Spcine"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={YoutubeLogoIcon}
              alt="Youtube Logo"
              width={30}
              height={30}
            />
          </a>
        ),
        linkedin: (
          <a
            href="https://br.linkedin.com/company/spcine"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={LinkedinLogoIcon}
              alt="LinkedIn Logo"
              width={30}
              height={30}
            />
          </a>
        ),
      },
    },
    infos: {
      end: (
        <p>
          <img src={PointerMapIcon} alt="Endereço" width={20} height={20} />
          <span style={{ margin: 10 }}>São Paulo Film Commission | Spcine</span>
          <br />
          <span>
            Rua Libero Badaró nº 293 Conjunto 22B – Centro -São Paulo, SP
            01009-907
          </span>
        </p>
      ),
      tel: (
        <p>
          <img src={PhoneCallIcon} alt="Telefone" width={20} height={20} />
          <a href="tel:+ 55 11 3117 3100" style={{ margin: 10 }}>
            + 55 11 3117 3100
          </a>
        </p>
      ),
      email: (
        <p>
          <img src={MailLogoIcon} alt="E-mail" width={20} height={20} />
          <a href="mailto:filmesp@spcine.com.br" style={{ margin: 10 }}>
            filmesp@spcine.com.br
          </a>
        </p>
      ),
      obs: (
        <p>
          <span>
            Dúvidas e Consultas:{' '}
            <a href="http://t.me/spfilmcommission" style={{ margin: 5 }}>
              Clique Aqui
            </a>
          </span>
        </p>
      ),
    },
    cienciaFilmagemInterna: {
      dicaForm:
        'Caso haja interferência em via/passeio público, como: reserva de vagas, ocupação da calçada, bloqueios de via ou calçada, a produção deverá solicitar à SPFilm',
      dicaData:
        'Neste campo deve ser informado o meio de exibição da obra (canal e/ou plataforma que o conteúdo será exibido ou comercializado). Os dados sobre veiculação prevista da obra são exclusivamente para uso interno da SPFilm.',
      display: 'flex',
    },
    labels: {
      tooltipQuestionNovaObra:
        'Neste campo deve ser informado o meio de exibição da obra (canal e/ou plataforma que o conteúdo será exibido ou comercializado). Os dados sobre veiculação prevista da obra são exclusivamente para uso interno da SPFilm.',
      tooltipNovaLocacao:
        'A São Paulo Film Comission é responsável por atender filmagens somente em espaços públicos de administração do Município de São Paulo. Para filmagens em espaços de outra administração (Estado, União, privados), o contrato deverá ser feito diretamente com os responsáveis. Em caso de dúvidas, contate nossa equipe pelo filmesp@spcine.com.br',
      login: {
        descriptionLogin: (
          <span>
            São Paulo é <b>AUDIOVISUAL</b>
          </span>
        ),
      },
      homeUser: {
        responsavelSpFilmColumnObrasTable: 'Responsável SP Film',
      },
      solicitacaoNovaObra: {
        responsavelSpFilmContatoMulti:
          'Dados do responsável pelo contato junto a São Paulo Film Commission:',
      },
      locacao: {
        dateHourEdit: 'Alteração a ser analisada pela SPFilm',
      },
      obra: {
        contatoResponsavelSpFilmComission:
          'Contato junto a São Paulo Film Comission',
      },
      solicitacaoNovaLocacaoLocacaoEspacosCultura: {
        equipamentosDaSmc: 'Equipamentos da SMC',
      },
      solicitacaoNovaLocacaoLocacaoFilmagemEsportivo: {
        espacoLocacao: 'Nome do Centro de Esportes ou Clube Escola, etc',
      },
      solicitacaoNovaLocacaoLocacaoOutrosEspacos: {
        tipoDeEspaco: 'Tipo de Espaço (ex: aterro sanitário, piscinão etc)',
      },
    },
    selects: {
      system: {
        chatLocacao: [
          {
            id: 100,
            type_file: '.pdf',
            label: 'Anuência de Pagamento em Bens e/ou Serviços',
            description:
              'Prezado(a) solicitante, informamos que a Anuência de Pagamento em Bens e/ou Serviços\n' +
              'referente a essa locação já se encontra disponível em anexo.\n' +
              'O documento deverá ser assinado pelo(a) responsável legal da produtora que consta\n' +
              'designado(a) em contrato social ou procuração e enviado para a SPFilm através do sistema\n' +
              'a qualquer momento até as 17h do dia útil que antecede a filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 101,
            type_file: '.pdf',
            label: 'Atestado de Arrecadação',
            description:
              'Prezado(a) solicitante, informamos que a Atestado de Arrecadação referente a essa locação\n' +
              'já se encontra disponível em anexo.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 103,
            type_file: '.pdf',
            label: 'Aviso Bloqueio Via',
            description:
              'Prezado(a) solicitante, informamos que o Aviso - Bloqueio de Via referente a essa locação já\n' +
              'se encontra disponível em anexo. Recomendamos o preenchimento e envio com antecedência\n' +
              'aos moradores, comerciantes e demais envolvidos e afetados na região do bloqueio em\n' +
              'questão.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 104,
            type_file: '.pdf',
            label: 'Aviso – Desligamento de Iluminação',
            description:
              'Prezado(a) solicitante, informamos que o Aviso - Desligamento de Iluminação referente a\n' +
              'essa locação já se encontra disponível em anexo. Recomendamos o preenchimento e envio\n' +
              'com antecedência aos moradores, comerciantes e demais envolvidos e afetados na região do\n' +
              'desligamento em questão.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 105,
            type_file: '.pdf',
            label: 'Boleto(s) da CET',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível para pagamento o(s)\n' +
              'Boleto(s) da CET referente(s) a essa locação. Após o pagamento, o comprovante deverá ser\n' +
              'enviado através do sistema até as 17h do dia útil que antecede a filmagem.Em caso de erro no pagamento, contate imediatamente a equipe da SPFilm.\n' +
              'O descumprimento de envio do(s) comprovante(s) poderá acarretar a negativa da solicitação de\n' +
              'filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 106,
            type_file: '.pdf',
            label: 'Carta de Apoio - Alvará',
            description:
              'Prezado(a) solicitante, informamos que a Carta de Apoio - Alvará referente a essa locação já\n' +
              'se encontra disponível em anexo.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 107,
            type_file: '.pdf',
            label: 'Croqui(s)',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível o(s) Croqui(s) referente(s) a\n' +
              'essa locação. A produção deverá operacionalizar as orientações dispostas neste documento\n' +
              'para realização da filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 108,
            type_file: '.pdf',
            label: 'Guia DAMSP',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível para pagamento a(s) Guia\n' +
              'DAMSP referente(s) a essa locação. Após o pagamento, o comprovante deverá ser enviado\n' +
              'através do sistema até as 17h do dia útil que antecede a filmagem.\n' +
              'Em caso de erro no pagamento, contate imediatamente a equipe da SPFilm.\n' +
              'O descumprimento de envio do(s) comprovante(s) poderá acarretar a negativa da solicitação de\n' +
              'filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 109,
            type_file: '.pdf',
            label: 'Lista de Bens e/ou Serviços',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível a Lista de Bens e/ou\n' +
              'Serviços solicitadas como pagamento referente a essa locação. A produção tem o prazo de 5\n' +
              '(cinco) dias úteis para efetuar a compra e enviar o comprovante de pagamento/notas fiscais\n' +
              'através do sistema pra a SPFilm.Caso a produção não encontre os bens/serviços solicitados ou tenha algum outro problema na\n' +
              'compra, deve contatar imediatamente a equipe da SPFilm.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 110,
            type_file: '.pdf',
            label: 'Manifestação de Interesse em Doar',
            description:
              'Prezado(a) solicitante, informamos que a Manifestação de Interesse em Doar referente a\n' +
              'essa locação já se encontra disponível em anexo.\n' +
              'O documento deverá ser assinado pelo(a) responsável legal da produtora que consta\n' +
              'designado(a) em contrato social ou procuração e enviado para a SPFilm através do sistema.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 111,
            type_file: '.pdf',
            label: 'Modelo – Faixa Bloqueio',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível o Modelo – Faixa Bloqueio\n' +
              'referente a essa locação. A produção deverá confeccionar a faixa conforme especificações\n' +
              'dispostas neste documento, bem como realizar a instalação com 3 dias de antecedência.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 112,
            type_file: '.pdf',
            label: 'Modelo ÓTIMA',
            description:
              'Prezado(a) solicitante, informamos que o Modelo para Autorização do Uso de Imagem\n' +
              'referente a essa locação já se encontra disponível em anexo.\n' +
              'O documento deverá ser preenchido pelo(a) produção e enviado para a SPFilm através do\n' +
              'sistema até as 17h do dia útil que antecede a filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 113,
            type_file: '.pdf',
            label:
              'Procedimento de Filmagens – Zonas Exclusivamente Residenciais',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível o Procedimento de\n' +
              'Filmagens – Zonas Exclusivamente Residenciais referente a essa locação. A produção\n' +
              'deverá seguir as orientações dispostas neste documento conforme acordado entre entidades\n' +
              'do setor e gestão pública.Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 114,
            type_file: '.pdf',
            label: 'Recibo',
            description:
              'Prezado(a) solicitante, informamos que o(s) Recibo(s) referente a essa locação já se\n' +
              'encontra(m) disponível(is) em anexo.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 115,
            type_file: '.pdf',
            label: 'Relatório Pós-Filmagem',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível o Relatório Pós-Filmagem\n' +
              'referente a essa locação. A produção deverá preencher e enviar para a SPFilm através do\n' +
              'sistema em até 02 dias úteis após a filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 116,
            type_file: '.pdf',
            label: 'Relatório de Visita Técnica',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível o Relatório de Visita\n' +
              'Técnica referente a essa locação. A produção deverá levar para a Visita Técnica (VT) o Relatório\n' +
              'Impresso para preenchimento em conjunto com o funcionário(a) do equipamento que\n' +
              'acompanhará a visita. Após o preenchimento, o documento deverá ser assinado pelo(a)\n' +
              'produtor(a) responsável e pelo funcionário que acompanhou a VT e enviado através do\n' +
              'sistema para a SPFilm em até 1 (um) dia útil após a realização.\n' +
              'O cancelamento de visitas agendadas deve ser feito em até 5 horas úteis antes do horário de\n' +
              'realização\n' +
              'A tolerância de atrasos para visitas agendadas é de 1 hora, mediante aviso e disponibilidade\n' +
              'do(s) gestor(es).\n' +
              'O descumprimento de envio do(s) relatório(s) poderá acarretar a negativa da solicitação de\n' +
              'filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 117,
            type_file: '.pdf',
            label: 'Termo de Recebimento de Doação',
            description:
              'Prezado(a) solicitante, informamos que o Termo de Recebimento de Doação referente a essa\n' +
              'locação já se encontra disponível em anexo.Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 118,
            type_file: '.pdf',
            label: 'Termo de Recebimento de Pagamento em Bens e/ou Serviços',
            description:
              'Prezado(a) solicitante, informamos que o Termo de Recebimento de Pagamento em Bens\n' +
              'e/ou Serviços referente a essa locação já se encontra disponível em anexo.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 119,
            type_file: '.pdf',
            label: 'Termo de Visita de Locação',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível o Termo de Visita de\n' +
              'Locação referente a essa locação. A produção deverá levá-lo para a Visita de Locação para\n' +
              'preenchimento e enviar posteriormente através do sistema para a SPFilm.\n' +
              'O cancelamento de visitas agendadas com acompanhamento deve ser feito em até 5 horas úteis\n' +
              'antes do horário de realização.\n' +
              'A tolerância de atrasos para visitas agendadas com acompanhamento é de 1 hora, mediante\n' +
              'aviso e disponibilidade do(s) gestor(es).\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 120,
            type_file: '.pdf',
            label: 'Transferências - Pagamentos',
            description:
              'Prezado(a) solicitante, informamos que, para pagamento dessa locação, a produção deverá\n' +
              'realizar a transferência/depósito conforme dados abaixo.\n' +
              '(SPFilm preenche os dados da Conta corrente e recebedor)\n' +
              'Após o pagamento, o comprovante deverá ser enviado através do sistema até as 17h do dia\n' +
              'útil que antecede a filmagem.\n' +
              'Em caso de erro no pagamento, contate imediatamente a equipe da SPFilm.\n' +
              'O descumprimento de envio do(s) comprovante(s) poderá acarretar a negativa da solicitação de\n' +
              'filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
        ],
        chatObra: [
          {
            id: 6,
            type_file: '.pdf',
            label: 'Carta de Apoio - Geral',
            description:
              'Prezado(a) solicitante, informamos que a Carta de Apoio – Geral referente a essa obra já se\n' +
              'encontra disponível em anexo.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 7,
            type_file: '.pdf',
            label: 'Declaração de Responsabilidade – Pessoa Física',
            description:
              'Prezado(a) solicitante, informamos que a Declaração de Responsabilidade referente à sua obra\n' +
              'já se encontra disponível em anexo.O documento deverá ser assinado pelo(a) responsável da obra que consta designado(a) no\n' +
              'texto e duas testemunhas da equipe e enviado para a SPFilm através do sistema a qualquer\n' +
              'momento até as 17h do dia útil que antecede a filmagem.\n' +
              'O descumprimento de envio da declaração de responsabilidade assinada dentro do prazo poderá\n' +
              'acarretar a negativa da solicitação de filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 8,
            type_file: '.pdf',
            label: 'Declaração de Responsabilidade – Pessoa Jurídica',
            description:
              'Prezado(a) solicitante, informamos que a Declaração de Responsabilidade referente à sua obra\n' +
              'já se encontra disponível em anexo.\n' +
              'O documento deverá ser assinado pelo(a) responsável legal da produtora que consta\n' +
              'designado(a) no texto e duas testemunhas da equipe e enviado para a SPFilm através do\n' +
              'sistema a qualquer momento até as 17h do dia útil que antecede a filmagem. Caso seja\n' +
              'necessário alterar o responsável legal da produtora, informe a SPFilm.\n' +
              'O descumprimento de envio da declaração de responsabilidade assinada dentro do prazo poderá\n' +
              'acarretar a negativa da solicitação de filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 9,
            type_file: '.pdf',
            label: 'Manual Identidade Visual e Créditos',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível o Manual de Identidade\n' +
              'Visual da Spcine para utilização nos créditos finais da obra. Conforme item VIII da Declaração\n' +
              'de Responsabilidade assinada, a inserção de créditos é obrigatória e pode ser feita através de\n' +
              'formato visual e/ou textual.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 10,
            type_file: '.pdf',
            label: 'Nota Fiscal',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível para pagamento a Nota\n' +
              'Fiscal de remuneração pelos serviços prestados pela São Paulo Film Commissionreferente a essa produção. Após o pagamento, o comprovante deverá ser enviado através do\n' +
              'sistema para finalização da obra.\n' +
              'Em caso de erro no pagamento, contate imediatamente a equipe da SPFilm.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 11,
            type_file: '.pdf',
            label: 'Autorizações',
            description:
              'Prezado(a) solicitante, informamos que a Autorização referente a essa locação já se encontra\n' +
              'disponível em anexo. Recomendamos que haja fácil acesso a esse documento no dia da\n' +
              'filmagem para apresentação caso solicitado.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtore',
          },
        ],
        chatUser: [
          {
            id: 1,
            type_file: '.pdf',
            label: 'Comprovante Pagamento – Nota Fiscal SPFilm',
          },
          {
            id: 2,
            type_file: '.pdf',
            label: 'Declaração de Responsabilidade Assinada',
          },
          {
            id: 3,
            type_file: '.pdf',
            label: 'Ofício Instituição de Ensino – Filmagens Estudantis',
          },
          {
            id: 4,
            type_file: '.pdf',
            label:
              'Ofício – Entidades sem Fins Lucrativos para Campanhas de Interesse Público',
          },
          {
            id: 5,
            type_file: '.pdf',
            label: 'Procuração - Responsável Legal',
          },
          {
            id: 'outros',
            label: 'Descrever...',
          },
        ],
      },
    },
    documents: {
      system: {
        locacaoDocs: [
          {
            id: 1,
            keyword: Object.keys({ precosFilmagensEquipamentosPublicos })[0],
            name: `Preços das Filmagens em Equipamentos Públicos`,
            file: `Precos_das_Filmagens_em_Equipamentos_Publicos.pdf`,
            url: `${process.env.PUBLIC_URL}${precosFilmagensEquipamentosPublicos}`,
          },
          {
            id: 2,
            keyword: Object.keys({ precosFilmagensVia })[0],
            name: `Preços das Filmagens em Vias`,
            file: `Precos_das_Filmagens_em_Vias.pdf`,
            url: `${process.env.PUBLIC_URL}${precosFilmagensVia}`,
          },
        ],
      },
    },
  },
  ilhacine: {
    tenant: 'ilhacine',
    displayName: 'Ilhacine',
    images: {
      system: {
        spFilmComissionLogo: ilhaBelaLogo,
        spcineLogo: '',
        bgHeader: heroLoginBgIlhaBela,
        altLogo: '',
        altCultura: 'Prefeitura de Ilha Bela Logo',
        culturaSpLogo: prefeituraIlhaBelaLogo,
        companyProfileLogo: companyProfileLogoIlhaBela,
        spcineLogoNegativo: '',
        culturaSpLogoNegativo: (
          <img
            src={ilhaBelaLogoNegativo}
            alt="Secretaria de Cultura Logo"
            width={290}
          />
        ),
      },
      login: {
        heroLoginBg: heroLoginBgIlhaBela,
        infoBalls: '',
        lafcnLogoFooter: '',
        associationFilmLogoFooter: '',
      },
    },
    login: {
      duvidasFrequentes: 'duvidas-frequentes',
      urlDoc: `${process.env.PUBLIC_URL}${CatalogoLocacaoIlhaBela}`,
    },
    social: {
      login: {
        facebook: (
          <a
            href="https://www.facebook.com/PrefeituraIlhabela"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={FacebookLogoIcon}
              alt="Facebook Logo"
              width={30}
              height={30}
            />
          </a>
        ),
        twitter: '',
        instagram: (
          <a
            href="https://www.instagram.com/prefeituradeilhabelaoficial/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={InstagramLogoIcon}
              alt="Instagram Logo"
              width={30}
              height={30}
            />
          </a>
        ),
        telegram: '',
        youtube: (
          <a
            href="https://www.youtube.com/channel/UCGDXc2bhsr5Fqv_9X-B8AAQ"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={YoutubeLogoIcon}
              alt="Youtube Logo"
              width={30}
              height={30}
            />
          </a>
        ),
        linkedin: '',
      },
    },
    infos: {
      end: (
        <p>
          <img src={PointerMapIcon} alt="Endereço" width={20} height={20} />
          <span style={{ margin: 10 }}>Ilhacine</span>
          <br />
          <span>
            R. Prefeito Mariano Procópio de Araújo Carvalho, 86 - Perequê
          </span>
        </p>
      ),
      tel: '',
      email: '',
      obs: '',
    },

    cienciaFilmagemInterna: {
      dicaForm:
        'Caso haja interferência em via/passeio público, como: reserva de vagas, ocupação da calçada, bloqueios de via ou calçada, a produção deverá solicitar à Ilhacine',
      dicaData:
        'Neste campo deve ser informado o meio de exibição da obra (canal e/ou plataforma que o conteúdo será exibido ou comercializado). Os dados sobre veiculação prevista da obra são exclusivamente para uso interno da Ilhacine.',
      display: 'none',
    },

    labels: {
      tooltipQuestionNovaObra:
        'Neste campo deve ser informado o meio de exibição da obra (canal e/ou plataforma que o conteúdo será exibido ou comercializado). Os dados sobre veiculação prevista da obra são exclusivamente para uso interno da Ilhacine.',
      tooltipNovaLocacao:
        'Ilhacine é responsável por atender filmagens somente em espaços públicos de administração do Município de Ilhabela. Para filmagens em espaços de outra administração (Estado, União, privados), o contrato deverá ser feito diretamente com os responsáveis. Em caso de dúvidas, contate nossa equipe.',
      login: {
        descriptionLogin: (
          <span>
            Filme é <b> Ilhabela</b>
          </span>
        ),
      },
      homeUser: {
        responsavelSpFilmColumnObrasTable: 'Responsável Ilhabela',
      },
      solicitacaoNovaObra: {
        responsavelSpFilmContatoMulti:
          'Dados do responsável pelo contato junto a Ilhacine:',
      },
      locacao: {
        dateHourEditTooltip: 'Alteração a ser analisada pela Ilhacine',
      },
      obra: {
        contatoResponsavelSpFilmComission:
          'Contato junto a Ilhacine',
      },
      solicitacaoNovaLocacaoLocacaoEspacosCultura: {
        equipamentosDaSmc: 'Equipamentos da Secretaria Municipal de Cultura',
      },
      solicitacaoNovaLocacaoLocacaoFilmagemEsportivo: {
        espacoLocacao: 'Nome do Espaço',
      },
      solicitacaoNovaLocacaoLocacaoOutrosEspacos: {
        tipoDeEspaco: 'Tipo de Espaço (ex: aterro sanitário, piscinão etc)',
      },
    },
    selects: {
      system: {
        chatLocacao: [
          {
            id: 100,
            type_file: '.pdf',
            label: 'Anuência de Pagamento em Bens e/ou Serviços',
            description:
              'Prezado(a) solicitante, informamos que a Anuência de Pagamento em Bens e/ou Serviços\n' +
              'referente a essa locação já se encontra disponível em anexo.\n' +
              'O documento deverá ser assinado pelo(a) responsável legal da produtora que consta\n' +
              'designado(a) em contrato social ou procuração e enviado para a IlhaCine através do sistema\n' +
              'a qualquer momento até as 17h do dia útil que antecede a filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 101,
            type_file: '.pdf',
            label: 'Atestado de Arrecadação',
            description:
              'Prezado(a) solicitante, informamos que a Atestado de Arrecadação referente a essa locação\n' +
              'já se encontra disponível em anexo.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 103,
            type_file: '.pdf',
            label: 'Aviso Bloqueio Via',
            description:
              'Prezado(a) solicitante, informamos que o Aviso - Bloqueio de Via referente a essa locação já\n' +
              'se encontra disponível em anexo. Recomendamos o preenchimento e envio com antecedência\n' +
              'aos moradores, comerciantes e demais envolvidos e afetados na região do bloqueio em\n' +
              'questão.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 104,
            type_file: '.pdf',
            label: 'Aviso – Desligamento de Iluminação',
            description:
              'Prezado(a) solicitante, informamos que o Aviso - Desligamento de Iluminação referente a\n' +
              'essa locação já se encontra disponível em anexo. Recomendamos o preenchimento e envio\n' +
              'com antecedência aos moradores, comerciantes e demais envolvidos e afetados na região do\n' +
              'desligamento em questão.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 105,
            type_file: '.pdf',
            label: 'Boleto(s) da CET',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível para pagamento o(s)\n' +
              'Boleto(s) da CET referente(s) a essa locação. Após o pagamento, o comprovante deverá ser\n' +
              'enviado através do sistema até as 17h do dia útil que antecede a filmagem.Em caso de erro no pagamento, contate imediatamente a equipe da IlhaCine.\n' +
              'O descumprimento de envio do(s) comprovante(s) poderá acarretar a negativa da solicitação de\n' +
              'filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 106,
            type_file: '.pdf',
            label: 'Carta de Apoio - Alvará',
            description:
              'Prezado(a) solicitante, informamos que a Carta de Apoio - Alvará referente a essa locação já\n' +
              'se encontra disponível em anexo.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 107,
            type_file: '.pdf',
            label: 'Croqui(s)',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível o(s) Croqui(s) referente(s) a\n' +
              'essa locação. A produção deverá operacionalizar as orientações dispostas neste documento\n' +
              'para realização da filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 108,
            type_file: '.pdf',
            label: 'Guia DAMSP',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível para pagamento a(s) Guia\n' +
              'DAMSP referente(s) a essa locação. Após o pagamento, o comprovante deverá ser enviado\n' +
              'através do sistema até as 17h do dia útil que antecede a filmagem.\n' +
              'Em caso de erro no pagamento, contate imediatamente a equipe da IlhaCine.\n' +
              'O descumprimento de envio do(s) comprovante(s) poderá acarretar a negativa da solicitação de\n' +
              'filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 109,
            type_file: '.pdf',
            label: 'Lista de Bens e/ou Serviços',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível a Lista de Bens e/ou\n' +
              'Serviços solicitadas como pagamento referente a essa locação. A produção tem o prazo de 5\n' +
              '(cinco) dias úteis para efetuar a compra e enviar o comprovante de pagamento/notas fiscais\n' +
              'através do sistema pra a IlhaCine.Caso a produção não encontre os bens/serviços solicitados ou tenha algum outro problema na\n' +
              'compra, deve contatar imediatamente a equipe da IlhaCine.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 110,
            type_file: '.pdf',
            label: 'Manifestação de Interesse em Doar',
            description:
              'Prezado(a) solicitante, informamos que a Manifestação de Interesse em Doar referente a\n' +
              'essa locação já se encontra disponível em anexo.\n' +
              'O documento deverá ser assinado pelo(a) responsável legal da produtora que consta\n' +
              'designado(a) em contrato social ou procuração e enviado para a IlhaCine através do sistema.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 111,
            type_file: '.pdf',
            label: 'Modelo – Faixa Bloqueio',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível o Modelo – Faixa Bloqueio\n' +
              'referente a essa locação. A produção deverá confeccionar a faixa conforme especificações\n' +
              'dispostas neste documento, bem como realizar a instalação com 3 dias de antecedência.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 112,
            type_file: '.pdf',
            label: 'Modelo ÓTIMA',
            description:
              'Prezado(a) solicitante, informamos que o Modelo para Autorização do Uso de Imagem\n' +
              'referente a essa locação já se encontra disponível em anexo.\n' +
              'O documento deverá ser preenchido pelo(a) produção e enviado para a IlhaCine através do\n' +
              'sistema até as 17h do dia útil que antecede a filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 113,
            type_file: '.pdf',
            label:
              'Procedimento de Filmagens – Zonas Exclusivamente Residenciais',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível o Procedimento de\n' +
              'Filmagens – Zonas Exclusivamente Residenciais referente a essa locação. A produção\n' +
              'deverá seguir as orientações dispostas neste documento conforme acordado entre entidades\n' +
              'do setor e gestão pública.Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 114,
            type_file: '.pdf',
            label: 'Recibo',
            description:
              'Prezado(a) solicitante, informamos que o(s) Recibo(s) referente a essa locação já se\n' +
              'encontra(m) disponível(is) em anexo.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 115,
            type_file: '.pdf',
            label: 'Relatório Pós-Filmagem',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível o Relatório Pós-Filmagem\n' +
              'referente a essa locação. A produção deverá preencher e enviar para a IlhaCine através do\n' +
              'sistema em até 02 dias úteis após a filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 116,
            type_file: '.pdf',
            label: 'Relatório de Visita Técnica',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível o Relatório de Visita\n' +
              'Técnica referente a essa locação. A produção deverá levar para a Visita Técnica (VT) o Relatório\n' +
              'Impresso para preenchimento em conjunto com o funcionário(a) do equipamento que\n' +
              'acompanhará a visita. Após o preenchimento, o documento deverá ser assinado pelo(a)\n' +
              'produtor(a) responsável e pelo funcionário que acompanhou a VT e enviado através do\n' +
              'sistema para a IlhaCine em até 1 (um) dia útil após a realização.\n' +
              'O cancelamento de visitas agendadas deve ser feito em até 5 horas úteis antes do horário de\n' +
              'realização\n' +
              'A tolerância de atrasos para visitas agendadas é de 1 hora, mediante aviso e disponibilidade\n' +
              'do(s) gestor(es).\n' +
              'O descumprimento de envio do(s) relatório(s) poderá acarretar a negativa da solicitação de\n' +
              'filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 117,
            type_file: '.pdf',
            label: 'Termo de Recebimento de Doação',
            description:
              'Prezado(a) solicitante, informamos que o Termo de Recebimento de Doação referente a essa\n' +
              'locação já se encontra disponível em anexo.Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 118,
            type_file: '.pdf',
            label: 'Termo de Recebimento de Pagamento em Bens e/ou Serviços',
            description:
              'Prezado(a) solicitante, informamos que o Termo de Recebimento de Pagamento em Bens\n' +
              'e/ou Serviços referente a essa locação já se encontra disponível em anexo.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 119,
            type_file: '.pdf',
            label: 'Termo de Visita de Locação',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível o Termo de Visita de\n' +
              'Locação referente a essa locação. A produção deverá levá-lo para a Visita de Locação para\n' +
              'preenchimento e enviar posteriormente através do sistema para a IlhaCine.\n' +
              'O cancelamento de visitas agendadas com acompanhamento deve ser feito em até 5 horas úteis\n' +
              'antes do horário de realização.\n' +
              'A tolerância de atrasos para visitas agendadas com acompanhamento é de 1 hora, mediante\n' +
              'aviso e disponibilidade do(s) gestor(es).\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 120,
            type_file: '.pdf',
            label: 'Transferências - Pagamentos',
            description:
              'Prezado(a) solicitante, informamos que, para pagamento dessa locação, a produção deverá\n' +
              'realizar a transferência/depósito conforme dados abaixo.\n' +
              '(IlhaCine preenche os dados da Conta corrente e recebedor)\n' +
              'Após o pagamento, o comprovante deverá ser enviado através do sistema até as 17h do dia\n' +
              'útil que antecede a filmagem.\n' +
              'Em caso de erro no pagamento, contate imediatamente a equipe da IlhaCine.\n' +
              'O descumprimento de envio do(s) comprovante(s) poderá acarretar a negativa da solicitação de\n' +
              'filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
        ],
        chatObra: [
          {
            id: 6,
            type_file: '.pdf',
            label: 'Carta de Apoio - Geral',
            description:
              'Prezado(a) solicitante, informamos que a Carta de Apoio – Geral referente a essa obra já se\n' +
              'encontra disponível em anexo.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 7,
            type_file: '.pdf',
            label: 'Declaração de Responsabilidade – Pessoa Física',
            description:
              'Prezado(a) solicitante, informamos que a Declaração de Responsabilidade referente à sua obra\n' +
              'já se encontra disponível em anexo.O documento deverá ser assinado pelo(a) responsável da obra que consta designado(a) no\n' +
              'texto e duas testemunhas da equipe e enviado para a IlhaCine através do sistema a qualquer\n' +
              'momento até as 17h do dia útil que antecede a filmagem.\n' +
              'O descumprimento de envio da declaração de responsabilidade assinada dentro do prazo poderá\n' +
              'acarretar a negativa da solicitação de filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 8,
            type_file: '.pdf',
            label: 'Declaração de Responsabilidade – Pessoa Jurídica',
            description:
              'Prezado(a) solicitante, informamos que a Declaração de Responsabilidade referente à sua obra\n' +
              'já se encontra disponível em anexo.\n' +
              'O documento deverá ser assinado pelo(a) responsável legal da produtora que consta\n' +
              'designado(a) no texto e duas testemunhas da equipe e enviado para a IlhaCine através do\n' +
              'sistema a qualquer momento até as 17h do dia útil que antecede a filmagem. Caso seja\n' +
              'necessário alterar o responsável legal da produtora, informe a IlhaCine.\n' +
              'O descumprimento de envio da declaração de responsabilidade assinada dentro do prazo poderá\n' +
              'acarretar a negativa da solicitação de filmagem.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 9,
            type_file: '.pdf',
            label: 'Manual Identidade Visual e Créditos',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível o Manual de Identidade\n' +
              'Visual da IlhaCine para utilização nos créditos finais da obra. Conforme item VIII da Declaração\n' +
              'de Responsabilidade assinada, a inserção de créditos é obrigatória e pode ser feita através de\n' +
              'formato visual e/ou textual.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 10,
            type_file: '.pdf',
            label: 'Nota Fiscal',
            description:
              'Prezado(a) solicitante, informamos que já se encontra disponível para pagamento a Nota\n' +
              'Fiscal de remuneração pelos serviços prestados pela Ilhacine referente a essa produção. Após o pagamento, o comprovante deverá ser enviado através do\n' +
              'sistema para finalização da obra.\n' +
              'Em caso de erro no pagamento, contate imediatamente a equipe da IlhaCine.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtores',
          },
          {
            id: 11,
            type_file: '.pdf',
            label: 'Autorizações',
            description:
              'Prezado(a) solicitante, informamos que a Autorização referente a essa locação já se encontra\n' +
              'disponível em anexo. Recomendamos que haja fácil acesso a esse documento no dia da\n' +
              'filmagem para apresentação caso solicitado.\n' +
              'Em caso de dúvidas ou para mais informações a respeito de procedimentos, consulte o Manual\n' +
              'de Produtore',
          },
        ],
        chatUser: [
          {
            id: 1,
            type_file: '.pdf',
            label: 'Comprovante Pagamento – Nota Fiscal IlhaCine',
          },
          {
            id: 2,
            type_file: '.pdf',
            label: 'Declaração de Responsabilidade Assinada',
          },
          {
            id: 3,
            type_file: '.pdf',
            label: 'Ofício Instituição de Ensino – Filmagens Estudantis',
          },
          {
            id: 4,
            type_file: '.pdf',
            label:
              'Ofício – Entidades sem Fins Lucrativos para Campanhas de Interesse Público',
          },
          {
            id: 5,
            type_file: '.pdf',
            label: 'Procuração - Responsável Legal',
          },
          {
            id: 'outros',
            label: 'Descrever...',
          },
        ],
      },
    },
    documents: {
      system: {
        locacaoDocs: [
          {
            id: 1,
            keyword: Object.keys({ precosFilmagensEquipamentosPublicos })[0],
            name: `Preços das Filmagens em Equipamentos Públicos`,
            file: `Ilhacine_Precos_das_Filmagens_em_Equipamentos_Publicos.pdf`,
            url: `${process.env.PUBLIC_URL}${ilhacinePrecosFilmagensEquipamentosPublicos}`,
          },
          {
            id: 2,
            keyword: Object.keys({ precosFilmagensVia })[0],
            name: `Preços das Filmagens em Vias`,
            file: `Ilhacine_Precos_das_Filmagens_em_Vias.pdf`,
            url: `${process.env.PUBLIC_URL}${ilhacinePrecosFilmagensEquipamentosPublicos}`,
          },
        ],
      },
    },
  },
};

export default clientResources[getSubdomainName()] || clientResources['spcine'];
